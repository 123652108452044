import React, { FunctionComponent } from "react";
import { Form, Input, Button } from "antd";
import styles from "./contact-form.module.scss";
import { submitContact } from "../../hooks/useSubmitContact";

const ContactForm: FunctionComponent<{ setShowForm: (value: boolean) => void }> = ({ setShowForm }) => {
  
  
  const onFinish = (values: {name: string, email: string, message: string}) => {
    submitContact(values);
    setShowForm(false);
  };

  const { TextArea } = Input; 

  return (
    <div className={styles.contactWrapper}>
      <Form
        className={styles.contactForm}
        name="contactForm"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        data-netlify="true"
      >
        <Form.Item
          name="name"
          rules={[
            { required: true, message: "Who are you?" },
            { max: 500, message: "Character limit: 500" },
          ]}
        >
          <Input size="large" placeholder="Your name" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "I want to reach out to you too.",
            }, {
              type: "email",
              message: "Please tell me your email."
            }
          ]}
        >
          <Input placeholder="Your email address" size="large" />
        </Form.Item>
        <Form.Item
          name="message"
          rules={[
            {
              type: "string",
              required: true,
              message: "What do you want to tell me?",
            },
            { max: 10000, message: "Character limit: 10000" },
          ]}
        >
          <TextArea
            size="large"
            placeholder="Your thoughts ..."
            autoSize={{ minRows: 5, maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { ContactForm };
