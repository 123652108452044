import React from "react";
import "react-typewriting-effect/dist/index.css";
import Typewriter from "typewriter-effect";
import styles from './header.module.scss';

export function Header() {
    return <div className={styles.greeting}>
    <h1 className={styles.heading}>
      <Typewriter
        onInit={(typewriter: any) => {
          typewriter.typeString("Hello, stranger!").start();
        }}
      />
    </h1>
    <div className={styles.subheading}>
    <h3>
    My name is Tim. <br/>
      I work in software engineering and information security. Beyond my fascination for technology I love sports, contemporary philosophy
      as well as mathematics. Hit me up with your thoughts.
    </h3>
    </div>
  </div>
}