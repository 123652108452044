import React, { useState } from "react";
import "./App.css";
import { ContactForm } from "./components/contact-form/contact-form";
import { Header } from "./components/header/header";
import { Thankyou } from "./components/thankyou/thankyou";

function App() {
  const [showForm, setShowFom] = useState(true);

  return (
    <div className="app">
      <div className="main">
      { showForm ?  <>
        <Header />
          <ContactForm setShowForm={setShowFom} />
          </> :
          <Thankyou />
      }
    </div>
    </div>
  );
}
export default App;